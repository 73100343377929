<template>
  <div class="layout-content">
    <template v-if="loading.isLoading.value">
      <HaLoading :manual="true" />
    </template>
    <HoTheHeader />
    <HaImage :src="`${content.url.cdn_frontend}top/kv.webp`" class="image" />
    <main class="main">
      <div class="container">
        <slot />
      </div>
      <div class="adjust-padding-container"></div>
    </main>
    <HoTheFooter />
  </div>
</template>

<script setup lang="ts">
import { ContentInjectionKey, content } from '@/utils/content'

// init
const i18n = useI18n()
const route = useRoute()
const config = useRuntimeConfig()

provide(ContentInjectionKey, content)

const loading = useLoading()
provide(loadingInjectionKey, loading)

useHead(() => ({
  meta: [
    {
      hid: 'description',
      name: 'description',
      content: `${i18n.t('meta.description')}`,
    },
    {
      hid: 'og:description',
      property: 'og:description',
      content: `${i18n.t('meta.description')}`,
    },
    {
      hid: 'og:site_name',
      property: 'og:site_name',
      content: `${i18n.t('meta.title.top')}`,
    },
    {
      hid: 'og:url',
      property: 'og:url',
      content: `${config.public.url}${route.fullPath}`,
    },
    {
      hid: 'og:image',
      property: 'og:image',
      content: `${config.public.url}/2024Winter/images/ogp${
        i18n.locale.value === 'en' ? '_en' : ''
      }.jpg`,
    },
  ],
}))
</script>

<style lang="scss" scoped>
@use '@/assets/styles/variables' as v;
@use '@/assets/styles/mixins' as m;

.layout-content {
  min-height: 100vh;
  position: relative;
  @include m.sp() {
    min-width: unset;
  }

  > .image {
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: v.$zindex-bg;
  }

  > .main {
    backdrop-filter: blur(10px);
    background-color: v.$v24w-base-mypage;
    padding-bottom: 128px;
    position: relative;
    @include m.sp() {
      padding-bottom: 64px;
    }

    > .container {
      background-color: rgba(v.$base-background-color, 0.92);
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
      margin: 0 auto;
      max-width: v.$pc-content-min-width;
      padding: 64px v.$space-xlarge * 2 128px;
      position: relative;
      z-index: v.$zindex-main;
      @include m.sp() {
        padding: v.$space-normal 0;
        width: auto;
      }
    }
  }
}
</style>
<style lang="scss">
// 各ページにスタイルを書かないように、ここだけscopedを外す。
@use '@/assets/styles/variables' as v;
@use '@/assets/styles/mixins' as m;

.adjust-padding-container > .padding-bottom {
  padding-bottom: 128px;
  @include m.sp() {
    padding-bottom: 64px;
  }
}
</style>
