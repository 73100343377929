import { ref, readonly, InjectionKey } from 'vue'

const useLoading = () => {
  const isLoading = ref(false)

  return {
    isLoading: readonly(isLoading),
    start: () => {
      isLoading.value = true
    },
    finish: () => {
      isLoading.value = false
    },
  }
}

export default useLoading

export type UseLoading = ReturnType<typeof useLoading>

export const loadingInjectionKey: InjectionKey<ReturnType<typeof useLoading>> =
  Symbol('useLoading')
